<script>
import { SweetModal } from "sweet-modal-vue";

export default {
  data() {
    return {
      form: {
        name: "",
        surname: "",
        phonenumber: "",
        email: "",
        subject: "",
        comments: "",
        documentCheck: "",
      },
      error: [],

    };
  },
  components: {
    SweetModal,
  },
  methods: {
    formatNumber() {
        var x = this.form.phonenumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.form.phonenumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    checkForm: function () {
      if (this.form.phonenumber.toString().length < 14) {
        this.error.push(
          "Lütfen telefon numaranızı başında 0 olmadan 10 hane olacak şekilde giriniz!"
        );
        return false;
      }
      if (this.form.comments.toString().length < 30) {
        this.error.push(
          "Lütfen mesajınızın en az 30 karakterden oluştuğuna emin olunuz!"
        );
        return false;
      }
      return true;
    },
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    resetForm() {
      this.form.name = "";
      this.form.email = "";
      this.form.surname = "";
      this.form.subject = "";
      this.form.comments = "";
      this.form.phonenumber = null;
      this.form.documentCheck = "";
    },
    onSubmit() {
      this.error = [];
      if (this.checkForm()) {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: this.encode({
            "form-name": "ask-question",
            ...this.form,
          }),
        })
          .then(() => console.log("successfully sent"))
          .catch((e) => console.error(e));
        this.$refs.modal.open();
        this.resetForm();
        return true;
      }
    },
  },
};
</script>

<template>
  <div>
    <!-- CONTACT FORM START-->
    <section class="section" id="contact">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <h2 class="section-title text-center">İLETİŞİM</h2>
            <div class="section-title-border margin-t-20"></div>
            <p
              class="
                section-subtitle
                text-dark text-center
                font-secondary
                padding-t-30
              "
            >
              Faydalanmak istediğiniz hizmet veya sorularınız için aşağıdaki formu doldurarak bize ulaşabilirsiniz.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2"></div>
          <div class="col-lg-8">
            <div class="custom-form mt-4 pt-4">
              <div id="message"></div>
              <form
                name="ask-question"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                @submit.prevent="onSubmit"
              >
                <input type="hidden" name="form-name" value="ask-question" />
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group mt-2">
                      <input
                        name="name"
                        id="name"
                        type="text"
                        class="form-control"
                        placeholder="Adınız*"
                        v-model="form.name"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group mt-2">
                      <input
                        name="surname"
                        id="surname"
                        type="text"
                        v-model="form.surname"
                        class="form-control"
                        placeholder="Soyadınız*"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group mt-2">
                      <input
                        name="phonenumber"
                        id="phonenumber"
                        type="text"
                        class="form-control"
                        @input="formatNumber"
                        v-model="form.phonenumber"
                        placeholder="Telefon Numaranız*"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group mt-2">
                      <input
                        name="email"
                        id="email"
                        type="email"
                        class="form-control"
                        v-model="form.email"
                        placeholder="Mail Adresiniz*"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group mt-2">
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.subject"
                        id="subject"
                        placeholder="Konu*"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group mt-2">
                      <textarea
                        name="comments"
                        id="comments"
                        rows="4"
                        class="form-control"
                        v-model="form.comments"
                        placeholder="Mesajınız..."
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group mt-2">
                      <div class="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            id="documentCheck"
                            name="documentCheck"
                            v-model="form.documentCheck"
                            required
                          />
                          <label class="custom-control-label" for="documentCheck">
                            
                            <a href="uyelik_aydinlatma_metni.pdf" class="read-btn" target="_blank">
                              Üyelik Aydınlatma Metni,
                            </a>
                            <a href="uyelik_sozlesmesi.pdf" class="read-btn" target="_blank">
                              Üyelik Sözleşmesi,
                            </a>
                            <a href="gizlilik_ve_cerez.pdf" class="read-btn" target="_blank">
                              Gizlilik ve Çerez Politikasını
                            </a> okuduğumu ve kabul ettiğimi onaylıyorum.</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12 text-right">
                    <button type="submit" class="btn btn-custom" value="Gönder">
                      Gönder
                    </button>
                  </div>
                </div>

                <sweet-modal icon="success" ref="modal">
                  Talebiniz tarafımıza ulaşmıştır. Ekibimiz en kısa sürede sizinle iletişime geçecektir. Bizi tercih ettiğiniz için teşekkür ederiz.
                </sweet-modal>

                <div class="row" v-if="error.length">
                  <div class="col-lg-12">
                    <div class="form-group mt-2">
                      <div class="alert alert-danger text-center" role="alert">
                        {{ error[0] }}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-2"></div>
        </div>
      </div>
    </section>
    <!-- CONTACT FORM END-->

    <!--START SOCIAL-->
    <section class="cta bg-light">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <ul class="list-inline social margin-t-20">
              <li class="list-inline-item">
                <a
                  class="social-icon"
                  href="https://www.instagram.com/cepteik/"
                  target="_blank"
                >
                  <i class="mdi mdi-instagram"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  class="social-icon"
                  href="https://twitter.com/cepteik"
                  target="_blank"
                >
                  <i class="mdi mdi-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 margin-t-30">
            <p class="margin-b-0 contact-title">
              
            </p>
          </div>
          <div class="col-lg-3 margin-t-30 text-right">
            <p class="contact-title">
              <i class="pe-7s-mail-open"></i>&nbsp; info@cepteik.com
            </p>
          </div>
        </div>
      </div>
    </section>
    <!--END SOCIAL-->
  </div>
</template>
